
import * as twicpicsRuntime$mak9aKK6VJ from 'D:/Github/oxbull-web-5-edge/node_modules/.pnpm/@nuxt+image@1.8.1_db0@0.2.1_idb-keyval@6.2.1_ioredis@5.4.2_magicast@0.3.5_rollup@4.29.1/node_modules/@nuxt/image/dist/runtime/providers/twicpics'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "twicpics",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['twicpics']: { provider: twicpicsRuntime$mak9aKK6VJ, defaults: {"baseURL":"https://oxbull.twic.pics/"} }
}
        